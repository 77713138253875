<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>


import { defineComponent } from 'vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet
  },
  methods: {
    
  },
  setup() {
    return {
      
    }
  }
});
</script>