import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '',
    component: () => import ('../LoggedInView.vue'),
    children: [
      {
        path: '',
        redirect: '/exercises'
      },
      {
        path: '/test',
        component: () => import ('../views/Test.vue')
      },
      {
        path: '/exercises',
        component: () => import ('../views/Exercises/ExercisesAll.vue')
      },
      {
        path: '/exercises/:slugCategory',
        component: () => import ('../views/Exercises/ExercisesCategory.vue')
      },
      {
        path: '/exercises/:slugCategory/:slugExercise',
        component: () => import ('../views/Exercises/ExercisesSingle.vue')
      },
      {
        path: '/workouts/unlock/:workoutSlug',
        component: () => import ('../views/Workouts/WorkoutsUnlock.vue')
      },
      {
        path: '/workouts',
        component: () => import ('../views/Workouts/WorkoutsAll.vue')
      },
      {
        path: '/workouts/:workoutSlug/week/:workoutWeek/day/:workoutDay',
        component: () => import ('../views/Workouts/WorkoutsSingle.vue')
      },
      {
        path: '/workouts/:workoutSlug/weeks',
        component: () => import ('../views/Workouts/WorkoutsSingleWeeks.vue')
      },
      {
        path: '/training',
        component: () => import ('../views/Training/TrainingSingle.vue')
      },
      {
        path: '/workouts/:workoutSlug/week/:workoutWeek',
        component: () => import ('../views/Workouts/WorkoutsSingleWeek.vue')
      },
      {
        path: '/custom',
        component: () => import ('../views/Custom/CustomAll.vue')
      },
      {
        path: '/custom/create',
        component: () => import ('../views/Custom/CustomCreate.vue')
      },
      {
        path: '/custom/import',
        component: () => import ('../views/Custom/CustomImport.vue')
      },
      {
        path: '/custom/plan/:planId/weeks',
        component: () => import ('../views/Custom/CustomSingleWeeks.vue')
      },
      {
        path: '/custom/plan/:planId/week/:workoutWeek',
        component: () => import ('../views/Custom/CustomSingleWeek.vue')
      },
      {
        path: '/custom/plan/:planId/week/:workoutWeek/day/:workoutDay',
        component: () => import ('../views/Custom/CustomSingle.vue')
      },
      {
        path: '/more',
        component: () => import ('../views/More/MoreAll.vue')
      },
      {
        path: '/settings',
        component: () => import ('../views/Settings/SettingsAll.vue')
      }
    ]
  },
  {
    path: '/r634166a8be3a044da62504a7',
    component: () => import ('../views/Auth/Register.vue')
  },
  {
    path: '/login',
    component: () => import ('../views/Auth/Login.vue')
  },
  {
    path: '/forgotpassword',
    component: () => import ('../views/Auth/ForgotPassword.vue')
  },
  {
    path: '/resetpassword',
    component: () => import ('../views/Auth/ResetPassword.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
