import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import axios from 'axios';
import VueAxios from 'vue-axios';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './css/main.css'

import './registerServiceWorker'


// Whenerver Server Gives 401 Status Code, it logouts and redirect to login page
axios.interceptors.response.use(function (response) {

  if(response.status === 401) {
    // alert("You are not authorized");
    localStorage.removeItem("jwt")
    localStorage.removeItem("userData")

  }
  return response;
  /*
    if (response.status === 401) {
      let msg = response.body.returnMessage
      localStorage.setItem('logoutReason', msg)
      auth.logout()
    }
    */



})


const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueAxios, axios);

// app.config.globalProperties.apiURI = 'http://192.168.0.118:1337';


if (process.env.NODE_ENV === 'development') {
  app.config.globalProperties.apiURI = 'http://localhost:1337';
  window.apiURI = app.config.globalProperties.apiURI;
} else {
  app.config.globalProperties.apiURI = 'https://appapi.resistancefitnesssystem.com';
  window.apiURI = app.config.globalProperties.apiURI;
}

let isUserAuthenticated = () => {
  if (localStorage.getItem("jwt") == null) {
    return false;
  } else {
    return true;
  }

}

router.beforeEach(function (to, from, next) {
  console.log('beforeEach', to.path + ' - Auth: ' + isUserAuthenticated())

  if ((to.path !== '/login' && to.path !== 'login' && to.path !== '/r634166a8be3a044da62504a7' && to.path !== 'r634166a8be3a044da62504a7') && !isUserAuthenticated()) {
    next({ path: '/login' })
  } else if ((to.path === '/login' || to.path === 'login' || to.path === '/r634166a8be3a044da62504a7' || to.path === 'r634166a8be3a044da62504a7') && isUserAuthenticated()) {
    next({ path: '/' })
  } else {
    next()
  }

})





router.isReady().then(() => {
  app.mount('#app');
});

